import keyMirror from 'keymirror';

export default keyMirror({
  MESSAGING_FAB: null,
  MESSAGING_MESSAGE_FLYOUT: null,
  WELCOME_MESSAGE: null,
  SEND_MESSAGE_TO: null,
  EMAIL_OPT_IN_TOGGLE: null,
  EMAIL_OPT_IN_MESSAGE: null,
  COLOR_SATURATION_KNOB: null,
  COLOR_HUE_SLIDER_KNOB: null
});
